import yasmin1 from './yasmin (1).jpg'
import yasmin2 from './yasmin (2).jpg'
import yasmin3 from './yasmin (3).jpg'
import yasmin4 from './yasmin (4).jpg'
import yasmin5 from './yasmin (5).jpg'
import yasmin6 from './yasmin (6).jpg'
import yasmin7 from './yasmin (7).jpg'
import yasmin8 from './yasmin (8).jpg'
import yasmin9 from './yasmin (9).jpg'
import yasmin10 from './yasmin (10).jpg'
import yasmin11 from './yasmin (11).jpg'
import yasmin12 from './yasmin (12).jpg'
import yasmin13 from './yasmin (13).jpg'
import yasmin14 from './yasmin (14).jpg'
import yasmin16 from './yasmin (16).jpg'
import yasmin15 from './yasmin (15).jpg'
import yasmin17 from './yasmin (17).jpg'
import yasmin18 from './yasmin (18).jpg'
import yasmin19 from './yasmin (19).jpg'
import yasmin20 from './yasmin (20).jpg'
import yasmin21 from './yasmin (21).jpg'
import yasmin22 from './yasmin (22).jpg'
import yasmin23 from './yasmin (23).jpg'
import yasmin24 from './yasmin (24).jpg'
import yasmin25 from './yasmin (25).jpg'
import yasmin26 from './yasmin (26).jpg'
import yasmin27 from './yasmin (27).jpg'
import yasmin28 from './yasmin (28).jpg'
import yasmin29 from './yasmin (29).jpg'
import yasmin30 from './yasmin (30).jpg'
import yasmin31 from './yasmin (31).jpg'
import yasmin32 from './yasmin (32).jpg'
import yasmin33 from './yasmin (33).jpg'
import yasmin34 from './yasmin (34).jpg'
import yasmin35 from './yasmin (35).jpg'
import yasmin36 from './yasmin (36).jpg'

export const yasminAlbum = [
  yasmin1,
  yasmin2,
  yasmin3,
  yasmin4,
  yasmin5,
  yasmin6,
  yasmin7,
  yasmin8,
  yasmin9,
  yasmin10,
  yasmin11,
  yasmin12,
  yasmin13,
  yasmin14,
  yasmin16,
  yasmin15,
  yasmin17,
  yasmin18,
  yasmin19,
  yasmin20,
  yasmin21,
  yasmin22,
  yasmin23,
  yasmin24,
  yasmin25,
  yasmin26,
  yasmin27,
  yasmin28,
  yasmin29,
  yasmin30,
  yasmin31,
  yasmin32,
  yasmin33,
  yasmin34,
  yasmin35,
  yasmin36,
]
