import eventos1 from './eventos (1).jpg'
import eventos2 from './eventos (2).jpg'
import eventos3 from './eventos (3).jpg'
import eventos4 from './eventos (4).jpg'
import eventos5 from './eventos (5).jpg'
import eventos6 from './eventos (6).jpg'
import eventos7 from './eventos (7).jpg'
import eventos8 from './eventos (8).jpg'

export const eventosAlbum = [
  eventos1,
  eventos2,
  eventos3,
  eventos4,
  eventos5,
  eventos6,
  eventos7,
  eventos8,
]
