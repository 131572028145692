import egresadas1 from './egresadas (1).jpg'
import egresadas2 from './egresadas (2).jpg'
import egresadas3 from './egresadas (3).jpg'
import egresadas4 from './egresadas (4).jpg'
import egresadas5 from './egresadas (5).jpg'
import egresadas6 from './egresadas (6).jpg'
import egresadas7 from './egresadas (7).jpg'

export const egresadasAlbum = [
  egresadas1,
  egresadas2,
  egresadas3,
  egresadas4,
  egresadas5,
  egresadas6,
  egresadas7,
]
