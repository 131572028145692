import clases1 from './clases (1).jpg'
import clases2 from './clases (2).jpg'
import clases3 from './clases (3).jpg'
import clases4 from './clases (4).jpg'
import clases5 from './clases (5).jpg'
import clases6 from './clases (6).jpg'
import clases7 from './clases (7).jpg'
import clases8 from './clases (8).jpg'
import clases9 from './clases (9).jpg'
import clases10 from './clases (10).jpg'
import clases11 from './clases (11).jpg'
import clases12 from './clases (12).jpg'
import clases13 from './clases (13).jpg'
import clases14 from './clases (14).jpg'

export const clasesAlbum = [
  clases1,
  clases2,
  clases3,
  clases4,
  clases5,
  clases6,
  clases7,
  clases8,
  clases9,
  clases10,
  clases11,
  clases12,
  clases13,
  clases14,
]
